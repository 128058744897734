import { MainProductRecommendationItem } from '@domain/app/product.domain';

export interface BadgeData {
  id: number;
  type: string;
  label: string;
  bgColor: string;
  textColor: string;
}

export interface TaskAndTransitionInput {
  compositionId?: string;
  compositionTitle?: string;
  transitionId?: string;
  taskId?: string;
  subtopicId?: string;
  selected?: boolean;
  target?: 'RECOMMENDED' | 'OTHER';
  skipSelection?: boolean;
  mainData?: MainProductRecommendationItem;
  sideOpen?: boolean;
}

export interface Appointment {
  timeDateString: Date; // used for timepicker
  timeStartString: string; // used for timepicker
  timeEndString: string; // used for timepicker
  appointmentString: string; // fully qualified date and time
  appointmentStart: string; // from backend
  appointmentEnd: string; // from backend
}

export enum TaskAndTransitionStateEnum {
  default = 'default',
  datepick = 'datepick',
  contact = 'contact',
  customer = 'customer',
}

export const formEmailPattern = '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,6}$';
