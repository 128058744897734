export enum RoutingPathMain {
  Default = '',
  Login = 'login',
  Init = 'init',
  Welcome = 'welcome',
  ClientList = 'main/client/list',
  ClientConsultations = 'main/client/consultations',
  TopicSelection = 'main/topics/selection',
  TopicOverview = 'main/topics',
  Consultation = 'main/consultation',
  TopicSummary = 'main/consultation/summary',
  Checkout = 'main/checkout',
  Finish = 'finish',
  Error = 'error',
  Result = 'main/result',
  ResultSummary = 'main/result/summary',
}

export enum RoutingPathPrep {
  TopicSelection = 'prep/topics/selection',
  TopicOverview = 'prep/topics',
  Consultation = 'prep/consultation',
  TopicSummary = 'prep/consultation/summary',
}

export enum RoutingPathOverlay {
  Notes = 'notes',
  Draw = 'drawing',
  Cart = 'cart',
  ConsultNew = 'consult-new',
  CustomerNew = 'customer-new',
  CustomerImport = 'customer-import',
  MainMenu = 'main-menu',
  Search = 'search',
  CompareAccounts = 'compare-accounts',
  Debug = 'debug',
}

export enum ConsultationStatusEnum {
  created = 'CREATED',
  inPreparation = 'IN_PREPARATION',
  preparationSent = 'PRELIMINARY_SEND',
  preparationDone = 'PRELIMINARY_FINISHED',
  mainConsultation = 'MAIN_CONSULTATION',
  checkoutDone = 'CHECKOUT_FINISHED',
  archived = 'ARCHIVED',
}

export enum InstanceStatusEnum {
  test = 'TEST',
  prod = 'PROD',
}

export enum SendMailStatusEnum {
  ok = 'OK',
  noCopy = 'NO_COPY_TO_USER',
}

export enum TopicStatusEnum {
  done = 'COMPLETE',
  inProgress = 'IN_PROGRESS',
  open = 'OPEN',
}

export enum TopicPositionEnum {
  logo = 'LOGO',
  bank = 'BANK',
  topLeft = 'TOP_LEFT',
  topCenter = 'TOP_CENTER',
  topRight = 'TOP_RIGHT',
  centerLeft = 'CENTER_LEFT',
  center = 'CENTER_CENTER',
  centerRight = 'CENTER_RIGHT',
  downLeft = 'DOWN_LEFT',
  downCenter = 'DOWN_CENTER',
  downRight = 'DOWN_RIGHT',
}

export enum MediaTypeEnum {
  image = 'IMAGE',
  pdf = 'PDF',
  video = 'VIDEO',
  link = 'LINK',
  docImage = 'DOC_IMAGE',
  logo = 'LOGO_IMAGE',
}

export enum QuestionImageBehavior {
  small = 'SMALL',
  large = 'LARGE',
  fixedSmall = 'SMALL_FIXED',
  fixedLarge = 'LARGE_FIXED',
}

export enum PanelStates {
  open = 'open',
  closed = 'closed',
}

export enum QuestionEnum {
  single = 'SINGLE',
  multi = 'MULTI',
  text = 'TEXT',
  singleImage = 'SINGLEIMAGE',
  multiImage = 'MULTIIMAGE',
  number = 'NUMBER',
  slider = 'SLIDER',
  expSlider = 'SLIDERDECLOG',
  toggle = 'TOGGLE',
  segments = 'SEGMENTRADIO',
  connectedSlider = 'CONNECTEDSLIDERS',
  date = 'DATE',
  variantCalculator = 'VARIANT_CALCULATOR',
  dataFieldGroup = 'DATA_FIELD_GROUP',
  tool = 'TOOL',
}

export enum QuestionTemplateEnum {
  default = 'GENERAL',
  products = 'PRODUCT_CONFIGURATION',
}

export enum GivenAnswerEnum {
  customer = 'CUSTOMER',
  consultant = 'CONSULTANT',
  consultation = 'CONSULTATION',
  history = 'HISTORY',
  none = 'NONE',
}

export enum PaymentInterval {
  fixed = 'FIXED',
  annually = 'ANNUALLY',
  monthly = 'MONTHLY',
  once = 'ONCE',
  expense = 'ONCE_TO_EXPENDITURE',
  customOffer = 'CUSTOM_OFFER',
}

export enum VatEnum {
  vat0 = 'PERCENT_0',
  vat7 = 'PERCENT_7',
  vat19 = 'PERCENT_19',
  vat19optional = 'PERCENT_19_OPTIONAL',
  vatSaving = 'SAVINGS_RATE',
}

export enum BenefitTypeEnum {
  time = 'TIME',
  money = 'MONEY',
  process = 'PROCESS',
  revenue = 'REVENUE',
  advantage = 'ADVANTAGES',
}

export enum ProductTemplateEnum {
  generic = 'GENERIC',
}

export enum RecommendationTypeEnum {
  product = 'PRODUCT',
  main = 'MAIN_PRODUCT',
  variant = 'VARIANT_PRODUCT',
  additional = 'ADDITIONAL_PRODUCT',
  task = 'TASK',
  transition = 'TRANSITION',
}

export enum RecommendationShowEnum {
  side = 'SHOW',
  transition = 'TRANSITION',
  task = 'TASK',
  infoProduct = 'INFO-PRODUCT',
  infoTask = 'INFO-TASK',
  infoTransition = 'INFO-TRANSITION',
}

export enum DataFieldTypeEnum {
  text = 'TEXT',
  longText = 'LONG_TEXT',
  number = 'NUMBER',
  date = 'DATE',
  dropdown = 'DROPDOWN',
  radio = 'RADIO_GROUP',
  checkbox = 'CHECKBOX',
  images = 'FILES_IMAGES',
  hidden = 'HIDDEN',
  price = 'PRICE',
}

export enum DataFieldElementTypeEnum {
  general = 'general',
  products = 'products',
  transitions = 'transitions',
  tasks = 'tasks',
  profile = 'profile',
}

export enum DataFieldTemplateEnum {
  temp1 = 'TEMP1',
  temp2 = 'TEMP2',
}

export enum DataFieldCategoryEnum {
  base = 'BASE',
  business = 'BUSINESS',
  account = 'ACCOUNT',
}

export enum PriorityEnum {
  low = 'LOW',
  normal = 'NORMAL',
  high = 'HIGH',
}

export enum ProductTypesEnum {
  products = 'PRODUCTS',
  tasks = 'TASKS',
  transitions = 'TRANSITIONS',
}

export enum ClientTabTypes {
  myClients = 0,
  allClients = 1,
}

export enum CustomerImportRequestTypeEnum {
  default = 'DEFAULT',
  all = 'ALL',
  vp = 'VP_VKS_IMPORT',
  mmm = 'MMM_IMPORT',
  zvr = 'ZVR_IMPORT',
}

export enum DocumentUploadEnum {
  image = 'IMAGE',
  pdf = 'PDF',
}

export enum DocumentUploadedByEnum {
  customer = 'CUSTOMER',
  consultant = 'CONSULTANT',
}

export enum NoteTypeEnum {
  drawing = 'DRAWING',
  text = 'TEXT',
}

export enum NoteOriginEnum {
  preparation = 'PREPARATION',
  consultation = 'MAIN_CONSULTATION',
}

export enum NotesModeEnum {
  Notes = 'NOTES',
  Documents = 'DOCUMENTS',
}

export enum NoteContextEnum {
  topicSelection = 'TOPIC_SELECTION',
  questionGroup = 'QUESTION_GROUP',
  noteManagement = 'NOTE_MANAGEMENT',
}

export enum DataFieldStatusEnum {
  none = 'NONE',
  notComplete = 'NOT_COMPLETE',
  mandatoryDone = 'MANDATORY_DONE',
  complete = 'COMPLETE',
}

export enum ContactTypeEnum {
  customer = 'CUSTOMER',
  consultant = 'CONSULTANT',
  expert = 'EXPERT',
}

export enum ExpertContactTypeEnum {
  expertContact = 'EXPERT_CONTACT',
  customerContact = 'CUSTOMER_CONTACT',
}

export enum ContractStatusEnum {
  none = 'NONE',
  error = 'ERROR',
  errorFinalized = 'ERROR_FINALIZED',
  generateFinalized = 'GENERATE_FINALIZED',
  generatePreview = 'GENERATE_PREVIEW',
  preview = 'PREVIEW',
  finalized = 'FINALIZED',
}

export enum FormOfAddressEnum {
  none = '',
  female = 'Frau',
  male = 'Herr',
}
